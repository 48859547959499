<template>
  <ClientOnly>
    <Html :lang="head.htmlAttrs?.lang" :dir="head.htmlAttrs?.dir">
      <Head>
        <template v-for="link in head.link" :key="link.id">
          <Link
            :id="link.id"
            :rel="link.rel"
            :href="link.href"
            :hreflang="link.hreflang"
          />
        </template>
        <template v-for="meta in head.meta" :key="meta.id">
          <Meta
            :id="meta.id"
            :property="meta.property"
            :content="meta.content"
          />
        </template>
      </Head>
      <Body>
        <div class="page-wrapper">
          <NavigationHeader class="non-clickable" />

          <main class="main-content">
            <NavigationBreadcrumbs class="non-clickable" />

            <slot />
          </main>

          <div
            ref="root"
            class="preview-wrapper"
            :class="{ locked: dataSending }"
          >
            <WidgetsAdminPreview
              v-if="preview && page?.widgets"
              :widgets="page.widgets"
              :new-widget-y-pos="newWidgetYpos"
              :scale="scale"
              :locale="locale"
              @add-widget="addWidget"
              @new-widget="newWidget"
              @edit-widget="editWidget"
              @remove-widget="removeWidget"
              @move-up-widget="moveUpWidget"
              @move-down-widget="moveDownWidget"
            />
          </div>
          <NavigationFooter class="nav-footer non-clickable" />
        </div>
      </Body>
    </Html>
  </ClientOnly>
</template>

<script lang="ts" setup>
import { useIsMobile } from '@/composables/useIsMobile'
import { useIsSafari } from '@/composables/useIsSafari'
import { usePreviewModule } from '@/composables/usePreviewModule'

const {
  root,
  newWidgetYpos,
  preview,
  page,
  dataSending,
  scale,
  addWidget,
  newWidget,
  removeWidget,
  moveUpWidget,
  moveDownWidget,
  editWidget,
  editHeader,
  locale
} = usePreviewModule()
provide('page', page)
provide('editHeader', editHeader)

useIsMobile()
useIsSafari()

const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'key',
  addSeoAttributes: true
})
</script>

<style lang="scss" scoped>
.non-clickable:deep(*) {
  pointer-events: none !important;
  user-select: none !important;
}

.page-wrapper {
  --vh: rem(800px);

  @include media-up(md) {
    --vh: rem(1024px);
  }

  @include media-up(lg) {
    --vh: rem(939px);
  }
}
</style>
